import React, { ComponentProps } from 'react';
import { IOptions } from '../../../interfaces/interfaces';

interface IProps extends ComponentProps<any> {
    id?: string;
    name?: string;
    icon?: string;
    value?: string | number;
    placeholder?: string;
    onChange?: any;
    required?: boolean;
    options: IOptions;
}

const Select = (props: IProps) => {
    const listOptions = () => {
        return (
            props.options.length > 0 &&
            props.options.map((option: any, indexRow: number) => (
                <option key={indexRow} value={option.value}>
                    {option.text}
                </option>
            ))
        );
    };

    const element = (
        <select
            id={props.id || ''}
            name={props.name || ''}
            value={props.value || ''}
            onChange={props.onChange || ''}
            placeholder={props.placeholder || ''}
            required={props.required || false}
            autoComplete={props.autocomplete || ''}
            aria-invalid={props.value === undefined ? true : false}
        >
            {listOptions()}
        </select>
    );

    return (
        <label>
            {props.icon && <span className={props.icon} />}
            {element}
        </label>
    );
};

export default Select;
