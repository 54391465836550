import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';

import logo from '../../assets/img/logo.svg';

import { useBloc } from '../../state/state';
import ProfilesCubit from '../../state/blocs/ProfilesCubit';

import apiProvider from '../../api';
import { UserContext } from '../../context/UserContext';
import { EMAIL_REGEX } from '../../utils/utils';

import Input from '../../components/elements/Input/Input';
import Loading from '../../ui/Loading/Loading';
import Card from '../../components/elements/Card/Card';

const Wrapper = styled.div`
    margin: 0 auto;
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
`;

const MainContent = styled.main`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    position: relative;
    flex: 1;
    height: 100vh;
`;

const Login = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [rememberMe, setRememberMe] = React.useState<number>(0);

    // Context
    const [, setUser] = React.useContext(UserContext);
    const [, { loadProfile }] = useBloc(ProfilesCubit);

    // Logout function
    const logout = () => {
        setUser(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        setUser(null);

        if (localStorage.getItem('USER_TOKEN') !== null) {
            const token = JSON.parse(localStorage.getItem('USER_TOKEN')!);
            const currentTime = new Date().getTime() / 1000;

            if (token.expireAt < currentTime) {
                localStorage.removeItem('USER_TOKEN');
            } else {
                setUser(token);
                loadProfile(token, logout);
                navigate('/');
            }
        }

        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const loginUser = (response: any) => {
        if (!response || response.error) return;

        setUser(response);

        if (rememberMe) {
            localStorage.setItem('USER_TOKEN', JSON.stringify(response));
        }

        navigate('/');
        loadProfile(response, logout);
    };

    const clickLogin = async () => {
        const response = await apiProvider.userLogin(email, password, logout);

        loginUser(response);
    };

    const clickForgotPassword = async () => {
        navigate('/forgot-my-password');
    };

    const clickRegister = async () => {
        navigate('/signup');
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <Wrapper className="wrapper">
            <MainContent>
                <div style={{ minWidth: '360px', width: '100%', maxWidth: '360px' }}>
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <img src={logo} alt="Logo" style={{ maxHeight: '128px' }} />
                    </div>
                    <Loading>
                        <Card noTitle>
                            <form>
                                <p style={{ textAlign: 'center' }}>{t('login.title')}</p>
                                <Input
                                    id={'email'}
                                    type={'email'}
                                    name={'email'}
                                    value={email}
                                    placeholder={'user@example.com'}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                                    isInvalid={!EMAIL_REGEX.test(email)}
                                    isInvalidHelp={t('input.valid-email')}
                                    required={true}
                                    autocomplete={'email'}
                                />
                                <Input
                                    id={'password'}
                                    type={'password'}
                                    name={'password'}
                                    value={password}
                                    placeholder={'••••••••'}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                                    isInvalid={password.length < 6}
                                    isInvalidHelp={t('input.valid-password')}
                                    required={true}
                                    autocomplete={'current-password'}
                                />
                                <fieldset>
                                    <input
                                        type="checkbox"
                                        id="remember"
                                        value={rememberMe}
                                        onClick={() => {
                                            setRememberMe(rememberMe === 0 ? 1 : 0);
                                        }}
                                    />
                                    <label htmlFor="remember">{t('login.remember-me')}</label>
                                </fieldset>
                                <button type="button" onClick={clickLogin} disabled={!EMAIL_REGEX.test(email) || password.length < 5}>
                                    {t('button.sign-in-web')}
                                </button>
                                <label>
                                    <span style={{ color: '#df135e' }} onClick={clickForgotPassword}>
                                        {t('login.forgot-password')}
                                    </span>
                                </label>
                                <label>
                                    <span style={{ color: '#df135e' }} onClick={clickRegister}>
                                        {t('common.register')}
                                    </span>
                                </label>
                            </form>
                        </Card>
                    </Loading>
                </div>
            </MainContent>
        </Wrapper>
    );
};

export default Login;
