import moment from 'moment';

import { CHART_DATE_FORMAT } from '../constants/constants';
import { IMetrics } from '../interfaces/interfaces';

export function generateMonthNames(numberOfMonths: number): string[] {
    const labels = [];

    for (let i = numberOfMonths; i >= 1; i = i - 1) {
        labels.push(moment().subtract(i, 'month').startOf('month').format(CHART_DATE_FORMAT));
    }

    const currentMonth = moment().format(CHART_DATE_FORMAT);
    labels.push(currentMonth);

    return labels;
}

export function sumPricePerMonthPerCurrency(labels: Array<string>, metrics: IMetrics): any[] {
    const perType = metrics.reverse().reduce(function (total: any, obj: any) {
        const key = obj['type'];

        if (!total['Income']) {
            total['Income'] = [];
            total['Expense'] = [];
        }

        if (key === 'Income') {
            total[key].push(obj);
        } else {
            total['Expense'].push(obj);
        }

        return total;
    }, {});

    const chartData = [];

    for (const type in perType) {
        const sumPerMonth = new Map();

        for (let i = 0; i < labels.length; i = i + 1) {
            sumPerMonth.set(labels[i], 0);
        }

        for (let i = 0; i < perType[type].length; i = i + 1) {
            const key = moment(perType[type][i].date).format(CHART_DATE_FORMAT);
            sumPerMonth.set(key, sumPerMonth.get(key) + perType[type][i].value);
        }

        const fixedDecimalPlaces = Object.values(Array.from(sumPerMonth.values())).map(function (element) {
            return Number(element.toFixed(2));
        });

        chartData.push({ label: type, data: fixedDecimalPlaces });
    }

    return chartData;
}

export function calculateDifferenceFromLastMonth(data: any): string {
    let currentMonth = 0;
    let lastMonth = 0;

    data.forEach((element: any) => {
        if (element.label === 'Income') {
            currentMonth += element.data[element.data.length - 1];
        } else {
            currentMonth -= element.data[element.data.length - 1];
        }

        if (element.label === 'Income') {
            lastMonth += element.data[element.data.length - 2];
        } else {
            lastMonth -= element.data[element.data.length - 2];
        }
    });

    if (lastMonth === 0) {
        return '0';
    }

    return Number(((currentMonth - lastMonth) / lastMonth) * 100).toFixed(2);
}

export function calculateCurrentMonthProfit(data: any): string {
    let currentMonth = 0;

    data.forEach((element: any) => {
        if (element.label === 'Income') {
            currentMonth += element.data[element.data.length - 1];
        } else {
            currentMonth -= element.data[element.data.length - 1];
        }
    });

    return Number(currentMonth).toFixed(2);
}
