import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { UserContext } from '../../context/UserContext';

import Sidebar from '../../components/Sidebar/Sidebar';
import Home from '../../views/Home/Home';
import UserProfile from '../../views/UserProfile/UserProfile';

const Main = () => {
    const navigate = useNavigate();

    // Context
    const [user] = React.useContext(UserContext);

    useEffect(() => {
        async function forceLogin() {
            if (user === null || user.jwt === null || user.jwt.length < 10) {
                navigate('/login');
                return;
            }
        }
        forceLogin();
    }, [user, navigate]);

    return (
        <div className="container">
            <Sidebar />
            <Routes>
                <Route path="/my-profile" element={<UserProfile />} />
                <Route path="/" element={<Home />} />
            </Routes>
        </div>
    );
};

export default Main;
