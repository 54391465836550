import React, { ComponentProps } from 'react';

interface IProps extends ComponentProps<any> {
    title?: string;
    icon?: string;
    loading?: boolean;
    noTitle?: boolean;
}

const Card = (props: IProps) => {
    const { title, icon, loading, noTitle } = props;

    return (
        <>
            {loading && <article aria-busy="true"></article>}
            {!loading && (
                <article>
                    {!noTitle && (
                        <header>
                            {icon && (
                                <span>
                                    <i className={icon} /> &nbsp;
                                </span>
                            )}
                            {title}
                        </header>
                    )}
                    {props.children}
                </article>
            )}
        </>
    );
};

Card.defaultProps = {
    title: undefined,
    icon: undefined,
    color: undefined,
    maximizable: undefined,
    minimizable: undefined,
    removeable: undefined,
    loading: undefined,
    gradient: undefined,
    outline: undefined,
    noPadding: undefined,
    reportTo: undefined,
};

export default Card;
