import { Cubit } from 'blac';
import { defaultDashboard } from '../../interfaces/defaultValues';
import { IDashboard } from '../../interfaces/interfaces';

export default class DashboardsCubit extends Cubit<IDashboard> {
    constructor() {
        super(defaultDashboard);
    }

    public readonly changeMonths = async (months: IDashboard) => {
        this.emit(months);
    };
}
