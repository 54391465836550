import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import 'moment/locale/en-gb';
import 'moment/locale/hr';
import 'moment/locale/ru';
import 'moment/locale/de';

import { de, en, hr, ru } from './locales/locales';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: process.env.REACT_APP_DEBUG,
        lng: 'en',
        fallbackLng: 'en',
        resources: {
            hr: {
                skeleton: hr.hr,
            },
            en: {
                skeleton: en.en,
            },
            de: {
                skeleton: de.de,
            },
            ru: {
                skeleton: ru.ru,
            },
        },
        ns: ['skeleton'],
        defaultNS: 'skeleton',
        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: false,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default',
        },
    });

export default i18n;
