import { Cubit } from 'blac';
import { toast } from 'react-toastify';
import apiProvider from '../../api';
import { IJwtToken, IMetric } from '../../interfaces/interfaces';
import i18n from '../../translations/i18n';

export default class MetricsCubit extends Cubit<IMetric[]> {
    constructor() {
        super([]);
    }

    public readonly loadMetrics = async (user: IJwtToken, onFail: () => void) => {
        if (user === null) {
            onFail();
            return;
        }

        const response = await apiProvider.getMetrics(user.jwt, onFail);

        if (!response || response.error) return;
        this.emit(response);
    };

    public readonly addMetric = async (user: IJwtToken, type: string, date: string, value: number, logout: () => void) => {
        const response = await apiProvider.postMetrics(user.jwt, type, date, value, logout);

        if (!response || response.error) return;
        toast.success(i18n.t(`${response}`) as string);
    };

    public readonly deleteMetric = async (user: IJwtToken, id: number, logout: () => void) => {
        const response = await apiProvider.deleteMetrics(user.jwt, id, logout);

        if (!response || response.error) return;
        toast.success(i18n.t(`${response}`) as string);
    };
}
