import React, { ComponentProps } from 'react';

import Card from '../elements/Card/Card';
import Chart from '../elements/Chart/Chart';

import { removeFirstLetter } from '../../utils/stringUtils';
import Button from '../elements/Button/Button';

import { useBloc } from '../../state/state';
import DashboardsCubit from '../../state/blocs/DashboardsCubit';

interface IProps extends ComponentProps<any> {
    title?: string;
    icon?: string;
    reportSince?: string;
    reportPercent: string;
    reporting?: string;
    reportingValue?: string;
    loading?: boolean;
    labels?: any;
    data?: any;
    legends?: any;
    noDataText?: string;
}

const DashboardReport = (props: IProps) => {
    const { title, icon, reportSince, reportPercent, reporting, reportingValue, loading, labels, data, noDataText } = props;

    const isPositive = +reportPercent > 0;
    const isNeutral = +reportPercent === 0;
    const isNaN = reportPercent === 'NaN';

    const [, { changeMonths }] = useBloc(DashboardsCubit);

    const clickChangeTo1Month = async () => {
        await changeMonths({ months: 1 });
    };

    const clickChangeTo3Month = async () => {
        await changeMonths({ months: 3 });
    };

    const clickChangeTo6Month = async () => {
        await changeMonths({ months: 6 });
    };

    const clickChangeTo12Month = async () => {
        await changeMonths({ months: 12 });
    };

    return (
        <Card title={title} icon={icon} loading={loading}>
            <div className="grid">
                <Button type={'button'} inline outline onClick={clickChangeTo1Month} label="" icon="fas fa-dice-one"></Button>
                <Button type={'button'} inline outline onClick={clickChangeTo3Month} label="" icon="fas fa-dice-three"></Button>
                <Button type={'button'} inline outline onClick={clickChangeTo6Month} label="" icon="fas fa-dice-six"></Button>
                <Button type={'button'} inline outline onClick={clickChangeTo12Month} label="" icon="fas fa-dice"></Button>
            </div>
            <div className="grid">
                <div>
                    {data.length === 0 ? (
                        <p>{noDataText}</p>
                    ) : (
                        <span style={{ color: `${isPositive ? 'green' : isNeutral || isNaN ? '' : 'red'}` }}>
                            <span>
                                <i className={`fas fa-arrow-${isPositive ? 'up' : isNeutral || isNaN ? 'right' : 'down'}`} />
                                {isPositive ? reportPercent : isNeutral || isNaN ? '0' : removeFirstLetter(reportPercent)}
                                &#37;&nbsp;
                            </span>
                            <small>{reportSince}</small>
                        </span>
                    )}
                </div>
                <div>
                    <span style={{ float: 'right' }}>
                        {reportingValue}&nbsp;<small>{reporting}</small>
                    </span>
                </div>
            </div>
            <Chart labels={labels} data={data} />
        </Card>
    );
};

export default DashboardReport;
