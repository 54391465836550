import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import logo from '../../assets/img/logo.svg';

import apiProvider from '../../api';
import { UserContext } from '../../context/UserContext';

import Loading from '../../ui/Loading/Loading';
import Card from '../../components/elements/Card/Card';

const Wrapper = styled.div`
    margin: 0 auto;
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
`;

const MainContent = styled.main`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    position: relative;
    flex: 1;
    height: 100vh;
`;

const VerifyAccount = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    // Context
    const [, setUser] = React.useContext(UserContext);

    // Logout function
    const logout = () => {
        setUser(null);
        navigate('/');
    };

    useEffect(() => {
        async function fetchData() {
            const query = new URLSearchParams(location.search);
            const response = await apiProvider.signupVerifyAccount(query.get('token') || '', logout);

            if (!response || response.error) {
                toast.error(response.msg);
                navigate('/login');
                return;
            }

            toast.success(t(response));
            navigate('/login');
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <Wrapper className="wrapper">
            <MainContent>
                <div style={{ minWidth: '360px', width: '100%', maxWidth: '360px' }}>
                    <div style={{ textAlign: 'center' }}>
                        <img src={logo} alt="Logo" style={{ maxHeight: '128px' }} />
                    </div>
                    <Loading>
                        <Card noTitle>
                            <br />
                            <br />
                            <h3 style={{ textAlign: 'center' }}>{t('verify-account.title')}</h3>
                            <br />
                        </Card>
                    </Loading>
                </div>
            </MainContent>
        </Wrapper>
    );
};

export default VerifyAccount;
