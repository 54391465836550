import { IDashboard, IJwtToken, IMetric, IProfile } from './interfaces';
import { isMobile } from 'react-device-detect';

import logo from '../assets/img/logo.svg';

export const defaultMetric: IMetric = {
    id: 1,
    user_id: 1,
    value: 100.0,
    type: 'Income',
    date: new Date().toISOString().substring(0, 10),
};

export const defaultProfile: IProfile = {
    active: true,
    registrationDate: 'Loading...',
    firstName: 'Piggy',
    lastName: 'Miggy',
    dateOfBirth: 'Loading...',
    webEmail: 'loading@email.com',
    facebookEmail: 'loading@email.com',
    googleEmail: 'loading@gmail.com',
    facebookImage: logo,
    googleImage: logo,
    consentGivenAt: 'Loading...',
};

export const defaultDashboard: IDashboard = {
    months: isMobile ? 3 : 12,
};

export const defaultJwtToken: IJwtToken = {
    jwt: '',
    expireAt: 0,
    email: '',
};
