import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import packageJson from '../../../package.json';
import i18n from '../../translations/i18n';

import { UserContext } from '../../context/UserContext';
import { useBloc } from '../../state/state';
import MetricsCubit from '../../state/blocs/MetricsCubit';

const Sidebar = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [modalStateLanguage, setModalStateLanguage] = React.useState<boolean>(false);
    const [modalStateSettings, setModalStateSettings] = React.useState<boolean>(false);

    const version = packageJson.version;

    const [, { loadMetrics }] = useBloc(MetricsCubit);

    // Context
    const [user, setUser] = React.useContext(UserContext);

    const logout = () => {
        setUser(null);
        navigate('/');
    };

    const clickLogout = () => {
        localStorage.clear();
        navigate('/login');
    };

    const clickIssue = () => {
        window.open('https://gitlab.com/Zanoshky/skeleton-web/-/issues', '_blank');
    };

    const clickCroatian = async () => {
        await i18n.changeLanguage('hr', (err) => {
            if (err) {
                console.error('Issue switching lang to hr', err);
                return;
            }
            moment.locale('hr');
            return true;
        });

        setModalStateLanguage(false);
    };

    const clickEnglish = async () => {
        await i18n.changeLanguage('en', (err) => {
            if (err) {
                console.error('Issue switching lang to en', err);
                return;
            }
            moment.locale('en');
            return true;
        });

        setModalStateLanguage(false);
    };

    const clickGerman = async () => {
        await i18n.changeLanguage('de', (err) => {
            if (err) {
                console.error('Issue switching lang to de', err);
                return;
            }
            moment.locale('de');
            return true;
        });

        setModalStateLanguage(false);
    };

    const clickRussian = async () => {
        await i18n.changeLanguage('ru', (err) => {
            if (err) {
                console.error('Issue switching lang to ru', err);
                return;
            }
            moment.locale('ru');
            return true;
        });

        setModalStateLanguage(false);
    };

    const clickRefresh = async () => {
        loadMetrics(user, logout);
    };

    const clickUserProfile = async () => {
        navigate('/my-profile');
    };

    const clickDashboard = async () => {
        navigate('/');
    };

    return (
        <>
            <nav>
                <ul>
                    <li>
                        <h1>Piggy</h1>
                    </li>
                </ul>
                <ul>
                    <li>
                        <span role="button" className={`${location.pathname === '/' ? '' : 'outline'} fas fa-chart-line`} onClick={clickDashboard} />
                    </li>
                    <li>
                        <span role="button" className={`${location.pathname === '/my-profile' ? '' : 'outline'} fas fa-user`} onClick={clickUserProfile} />
                    </li>
                    <li>
                        <span role="button" className="outline fas fa-refresh" onClick={clickRefresh} />
                    </li>
                    <li>
                        <span role="button" className="outline fas fa-language" onClick={() => setModalStateLanguage(true)} />
                    </li>
                    <li>
                        <span role="button" className="outline fas fa-wrench" onClick={() => setModalStateSettings(true)} />
                    </li>
                    <li>
                        <span role="button" className="outline fas fa-sign-out-alt" onClick={clickLogout} />
                    </li>
                </ul>
            </nav>
            {modalStateLanguage && (
                <dialog id="modal-language" open>
                    <article style={{ minWidth: '360px', width: '100%', maxWidth: '360px' }}>
                        <h3>{t('sidebar.select-your-language')}</h3>
                        <ul>
                            <li>
                                <span role="link" onClick={clickCroatian}>
                                    {t('sidebar.croatian')}
                                </span>
                            </li>
                            <li>
                                <span role="link" onClick={clickEnglish}>
                                    {t('sidebar.english')}
                                </span>
                            </li>
                            <li>
                                <span role="link" onClick={clickGerman}>
                                    {t('sidebar.german')}
                                </span>
                            </li>
                            <li>
                                <span role="link" onClick={clickRussian}>
                                    {t('sidebar.russian')}
                                </span>
                            </li>
                        </ul>
                        <footer>
                            <span role="button" className="secondary" data-target="modal-language" onClick={() => setModalStateLanguage(false)}>
                                {t('button.close')}
                            </span>
                        </footer>
                    </article>
                </dialog>
            )}
            {modalStateSettings && (
                <dialog id="modal-settings" open>
                    <article style={{ minWidth: '360px', width: '100%', maxWidth: '360px' }}>
                        <h3>{t('sidebar.settings')}</h3>
                        <ul>
                            <li>
                                <span>
                                    <i className="fas fa-code-branch fa-flip-horizontal" />
                                    &nbsp;{t('footer.version')} {version}
                                </span>
                            </li>
                            <li>
                                <span role="link" onClick={clickIssue}>
                                    <i className="fas fa-exclamation-triangle" />
                                    &nbsp;{t('sidebar.report')}
                                </span>
                            </li>
                        </ul>

                        <footer>
                            <span role="button" className="secondary" data-target="modal-settings" onClick={() => setModalStateSettings(false)}>
                                {t('button.close')}
                            </span>
                        </footer>
                    </article>
                </dialog>
            )}
        </>
    );
};
export default Sidebar;
