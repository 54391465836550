import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Card from '../components/elements/Card/Card';
import Input from '../components/elements/Input/Input';
import Button from '../components/elements/Button/Button';
import Select from '../components/elements/Select/Select';

import MetricsCubit from '../state/blocs/MetricsCubit';
import { useBloc } from '../state/state';

import { UserContext } from '../context/UserContext';
import Loading from './Loading/Loading';

const AddForm = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [, { loadMetrics, addMetric }] = useBloc(MetricsCubit);

    const [type, setType] = React.useState<string>('Groceries');
    const [value, setValue] = React.useState<number>(0.0);
    const [date, setDate] = React.useState<string>(new Date().toISOString().substring(0, 10));
    const [user, setUser] = React.useContext(UserContext);

    // Logout function
    const logout = () => {
        setUser(null);
        navigate('/');
    };

    // INTERACTION
    const clickAddNew = async () => {
        if (type === '' || value === 0) {
            return;
        }

        await addMetric(user, type, date, value, logout);

        setType('Groceries');
        setValue(0);
        setDate(new Date().toISOString().substring(0, 10));
        loadMetrics(user, logout);
    };

    return (
        <Card title={t('home.add-new')} color="primary" icon={'fas fa-plus'} minimizable maximizable>
            <form>
                <Select
                    id={'type'}
                    name={'type'}
                    value={type}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setType(e.target.value)}
                    required={true}
                    options={[
                        {
                            value: 'Groceries',
                            text: t('select.groceries'),
                        },
                        {
                            value: 'Supplies',
                            text: t('select.supplies'),
                        },
                        {
                            value: 'Shopping',
                            text: t('select.shopping'),
                        },
                        {
                            value: 'Restaurant',
                            text: t('select.restaurant'),
                        },
                        {
                            value: 'Entertainment',
                            text: t('select.entertainment'),
                        },
                        {
                            value: 'Service',
                            text: t('select.service'),
                        },
                        {
                            value: 'Utility',
                            text: t('select.utility'),
                        },
                        {
                            value: 'Insurance',
                            text: t('select.insurance'),
                        },
                        {
                            value: 'Rent',
                            text: t('select.rent'),
                        },
                        {
                            value: 'Transport',
                            text: t('select.transport'),
                        },
                        {
                            value: 'Travel',
                            text: t('select.travel'),
                        },
                        {
                            value: 'Health',
                            text: t('select.health'),
                        },
                        {
                            value: 'Cash',
                            text: t('select.cash'),
                        },
                        {
                            value: 'Gift',
                            text: t('select.gift'),
                        },
                        {
                            value: 'Income',
                            text: t('select.income'),
                        },
                    ]}
                />
                <Input
                    id={'value'}
                    type={'number'}
                    name={'value'}
                    value={value}
                    placeholder={'0.00'}
                    min={0}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(Number(e.target.value))}
                    isInvalid={value === 0}
                    isInvalidHelp={t('input.valid-value')}
                    required={true}
                />
                <Input
                    id={'date'}
                    type={'date'}
                    name={'date'}
                    value={date}
                    placeholder={new Date().toISOString().substring(0, 10)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDate(e.target.value)}
                    isInvalid={date < '2000-01-01'}
                    isInvalidHelp={t('input.valid-value')}
                    required={true}
                />
                <Loading>
                    <Button block type={'button'} color={'primary'} onClick={clickAddNew} icon={'fas fa-add'} label={t('')} disabled={date < '2000-01-01' || value <= 0}></Button>
                </Loading>
            </form>
        </Card>
    );
};

export default AddForm;
