import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';

import logo from '../../assets/img/logo.svg';

import apiProvider from '../../api';
import { UserContext } from '../../context/UserContext';
import { EMAIL_REGEX } from '../../utils/utils';

import Input from '../../components/elements/Input/Input';
import Loading from '../../ui/Loading/Loading';
import Card from '../../components/elements/Card/Card';

const Wrapper = styled.div`
    margin: 0 auto;
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
`;

const MainContent = styled.main`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    position: relative;
    flex: 1;
    height: 100vh;
`;

const Signup = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [agree, setAgree] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [repeatPassword, setRepeatPassword] = React.useState<string>('');

    // Context
    const [, setUser] = React.useContext(UserContext);

    // Logout function
    const logout = () => {
        setUser(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        setUser(null);
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickRegister = async () => {
        const response = await apiProvider.signupRegisterAccount(email, password, logout);

        if (!response || response.error) return;

        toast.success(response);
        navigate('/login');
    };

    const clickLogin = async () => {
        navigate('/login');
    };

    const clickPrivacyPolicy = async () => {
        window.open('/privacy-policy', '_blank');
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <Wrapper className="wrapper">
            <MainContent>
                <div style={{ minWidth: '360px', width: '100%', maxWidth: '360px' }}>
                    <div style={{ textAlign: 'center' }}>
                        <img src={logo} alt="Logo" style={{ maxHeight: '128px' }} />
                    </div>
                    <Loading>
                        <Card noTitle>
                            <form>
                                <p style={{ textAlign: 'center' }}>{t('signup.title')}</p>
                                <Input
                                    id={'email'}
                                    type={'email'}
                                    name={'email'}
                                    value={email}
                                    placeholder={'user@example.com'}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                                    isInvalid={!EMAIL_REGEX.test(email)}
                                    isInvalidHelp={t('input.valid-email')}
                                    required={true}
                                    autocomplete={'email'}
                                />
                                <Input
                                    id={'password'}
                                    type={'password'}
                                    name={'password'}
                                    value={password}
                                    placeholder={'••••••••'}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                                    isInvalid={password.length < 6}
                                    isInvalidHelp={t('input.valid-password')}
                                    required={true}
                                    autocomplete={'new-password'}
                                />
                                <Input
                                    id={'repeatPassword'}
                                    type={'password'}
                                    name={'repeatPassword'}
                                    value={repeatPassword}
                                    placeholder={'••••••••'}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRepeatPassword(e.target.value)}
                                    isInvalid={repeatPassword.length < 6}
                                    isInvalidHelp={t('input.valid-repeat-password')}
                                    required={true}
                                    autocomplete={'new-password'}
                                />
                                <fieldset>
                                    <input type="checkbox" id="agreeTerms" name="terms" value="agree" onChange={() => setAgree(!agree)} />
                                    <label htmlFor="agreeTerms">
                                        {t('input.accept')}&nbsp;
                                        <span style={{ color: '#df135e' }} onClick={clickPrivacyPolicy}>
                                            {t('input.terms')}
                                        </span>
                                    </label>
                                </fieldset>
                                <button type="button" onClick={clickRegister} disabled={!agree || !EMAIL_REGEX.test(email) || password.length < 6 || repeatPassword !== password}>
                                    {t('button.register')}
                                </button>
                                <label>
                                    <span style={{ color: '#df135e' }} onClick={clickLogin}>
                                        {t('common.already-registered')}
                                    </span>
                                </label>
                            </form>
                        </Card>
                    </Loading>
                </div>
            </MainContent>
        </Wrapper>
    );
};

export default Signup;
