import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
// import * as Sentry from '@sentry/react';
// import packageJson from '../package.json';

import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import Main from './pages/Main/Main';
import VerifyAccount from './pages/VerifyAccount/VerifyAccount';
import ForgotMyPassword from './pages/ForgotMyPassword/ForgotMyPassword';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';

import ContextCombiner from './context/ContextCombiner';

// Sentry.init({
//     dsn: 'https://49da0c2697f3417bbe2a2a8d3ba7b769@o435848.ingest.sentry.io/5395876',
//     sampleRate: 0.25,
//     release: 'skeleton-web@' + packageJson.version,
//     environment: process.env.REACT_APP_ENV,
//     debug: process.env.REACT_APP_ENV === 'development',
// });

const App: React.FC = () => {
    document.addEventListener('contextmenu', function (event) {
        event.preventDefault();
    });

    return (
        <main>
            <ToastContainer autoClose={3000} position={toast.POSITION.TOP_RIGHT} />

            <ContextCombiner>
                <Router>
                    <Routes>
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/forgot-my-password" element={<ForgotMyPassword />} />
                        <Route path="/verify-account" element={<VerifyAccount />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="*" element={<Main />} />
                    </Routes>
                </Router>
            </ContextCombiner>
        </main>
    );
};

export default App;
