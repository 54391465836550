import React from 'react';
import { useTranslation } from 'react-i18next';
import { CURRENCY_FORMAT } from '../../constants/constants';

import Card from '../elements/Card/Card';
import Table from '../elements/Table/Table';

interface IProps {
    data: any;
}

const ReportByGroups = (props: IProps) => {
    const { t } = useTranslation();
    const { data } = props;

    const calculateMetrics = () => {
        const result: any = [];
        data.reduce(function (res: any, value: any) {
            if (!res[value.type]) {
                res[value.type] = { type: value.type, sum: 0 };
                result.push(res[value.type]);
            }
            res[value.type].sum += value.value;
            return res;
        }, {});

        result.sort(function (a: any, b: any) {
            return b.sum - a.sum;
        });

        return result;
    };

    const groupMetrics = (result: any) => {
        return (
            result.length > 0 &&
            result.map(function (metric: any) {
                return (
                    <tr key={metric.type}>
                        <td>{t(`select.` + metric.type.toLowerCase())}</td>
                        <td style={{ textAlign: 'right' }}>{CURRENCY_FORMAT.format(metric.sum)}</td>
                    </tr>
                );
            })
        );
    };

    const calculateProfit = (result: any) => {
        const temp: any = [];

        temp[0] = { type: 'Income', sum: 0 };
        temp[1] = { type: 'Expense', sum: 0 };
        temp[2] = { type: 'Profit', sum: 0 };

        for (let i = 0; i < result.length; i++) {
            if (result[i].type === 'Income') {
                temp[0].sum = result[i].sum;
            } else {
                temp[1].sum = temp[1].sum + result[i].sum;
            }
        }

        temp[2].sum = temp[0].sum - temp[1].sum;

        return temp;
    };

    const metricsGrouped = calculateMetrics();
    const income = calculateProfit(metricsGrouped);

    return (
        <Card title={t('home.report')} icon={'fas fa-chart-pie'} loading={data.length === 0} minimizable>
            <div className="grid">
                <div>
                    <Table thead={[]} tbody={groupMetrics(income)} />
                </div>
                <div>
                    <Table thead={[]} tbody={groupMetrics(metricsGrouped)} />
                </div>
            </div>
        </Card>
    );
};

export default ReportByGroups;
