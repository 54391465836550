export function capitalizeFirstLetter(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function replaceUnderscoreWithSpace(value: string): string {
    return value.split('_').join(' ');
}

export function replaceUnderscoreWithMinus(value: string): string {
    return value.split('_').join('-');
}

export function removeFirstLetter(value: string): string {
    return value.slice(1);
}
