import { Cubit } from 'blac';
import { toast } from 'react-toastify';
import apiProvider from '../../api';
import { defaultProfile } from '../../interfaces/defaultValues';
import { IJwtToken, IProfile } from '../../interfaces/interfaces';
import i18n from '../../translations/i18n';

export default class ProfilesCubit extends Cubit<IProfile> {
    constructor() {
        super(defaultProfile);
    }

    public readonly loadProfile = async (user: IJwtToken, onFail: () => void) => {
        const response = await apiProvider.getProfile(user.jwt, onFail);

        if (!response || response.error) return;
        this.emit(response);
    };

    public readonly updateProfile = async (user: IJwtToken, firstName: string, lastName: string, dateOfBirth: string, logout: () => void) => {
        const response = await apiProvider.putProfile(user.jwt, firstName, lastName, dateOfBirth, logout);

        if (!response || response.error) return;
        toast.success(i18n.t(`${response}`) as string);
    };

    public readonly deleteProfile = async (user: IJwtToken, logout: () => void) => {
        const response = await apiProvider.deleteProfile(user.jwt, logout);

        if (!response || response.error) return;
        toast.success(i18n.t(`${response}`) as string);
    };
}
