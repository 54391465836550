import React, { ComponentProps } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface Header {
    class?: string;
    label: string;
}

interface IProps extends ComponentProps<any> {
    label?: string;
    thead: Header[];
    tbody: any;
}

const Table = (props: IProps) => {
    const formulateHeader = () => {
        return props.thead.map((header) => {
            return (
                <th key={uuidv4()} scope="row">
                    {header.label}
                </th>
            );
        });
    };
    return (
        <table className="striped">
            <thead>
                {props.label && (
                    <tr>
                        <th scope="col">{props.label}</th>
                    </tr>
                )}
                <tr>{formulateHeader()}</tr>
            </thead>
            <tbody>{props.tbody}</tbody>
        </table>
    );
};

export default Table;
