import React, { ComponentProps } from 'react';

interface IProps extends ComponentProps<any> {
    id?: string;
    type?: string;
    name?: string;
    value?: string | number;
    icon?: string;
    min?: number;
    max?: number;
    label?: string;
    placeholder?: string;
    onChange?: (x: any) => void;
    isInvalid?: boolean;
    isInvalidHelp?: string;
    required?: boolean;
    autocomplete?: string;
    disabled?: boolean;
}

const Input = (props: IProps) => {
    const [showHelpOnFocus, setShowHelpOnFocus] = React.useState<boolean>(false);

    const inputElement = (
        <input
            id={props.id || ''}
            type={props.type || ''}
            name={props.name || ''}
            value={props.value || ''}
            min={props.min || undefined}
            max={props.max || undefined}
            onChange={props.onChange || (() => null)}
            onFocus={() => setShowHelpOnFocus(true)}
            aria-invalid={props.value === '' || props.value === 0 ? undefined : props.isInvalid ? true : false}
            placeholder={props.placeholder || ''}
            required={props.required || false}
            autoComplete={props.autocomplete || ''}
            disabled={props.disabled}
        />
    );

    return (
        <label>
            {props.label && (
                <label>
                    {props.icon && <span className={props.icon} />}
                    &nbsp;{props.label}
                </label>
            )}
            {!props.label && props.icon && <span className={props.icon} />}
            {inputElement}
            {showHelpOnFocus && props.isInvalid && <small>{props.isInvalidHelp || ''}</small>}
        </label>
    );
};

export default Input;
