import { hydrateRoot, createRoot } from 'react-dom/client';

import App from './App';
import * as serviceWorker from './serviceWorker';

import '@picocss/pico';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import '@fortawesome/fontawesome-free/css/brands.min.css';
import '@fontsource/pacifico';
import 'react-toastify/dist/ReactToastify.min.css';
import './translations/i18n';
import './overload-style.css';
const root = document.getElementById('root')!;

if (root && root.innerHTML !== '') {
    hydrateRoot(root, <App />);
} else {
    const rootRender = createRoot(root);
    rootRender.render(<App />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
