import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import './Overview.css';

import { IMetric } from '../../interfaces/interfaces';
import { CURRENCY_FORMAT } from '../../constants/constants';

import Card from '../elements/Card/Card';
import Table from '../elements/Table/Table';
import Button from '../elements/Button/Button';
import Loading from '../../ui/Loading/Loading';

interface IProps {
    data: any;
    onDelete: any;
}

const Overview = (props: IProps) => {
    const { t } = useTranslation();
    const { data } = props;

    const listMetrics = () => {
        return (
            data.length > 0 &&
            data.reverse() &&
            data.map(function (metric: IMetric) {
                return (
                    <tr key={metric.id}>
                        <td className="text-right" style={{ width: `${isMobile ? '10%' : '25%'}` }}>
                            {isMobile ? moment(metric.date).format('DD.MM') : moment(metric.date).format('LL')}
                        </td>
                        <td className="text-right" style={{ width: `${isMobile ? '40%' : '25%'}` }}>
                            {t(`select.` + metric.type.toLowerCase())}
                        </td>
                        <td className="text-right" style={{ width: `${isMobile ? '30%' : '25%'}` }}>
                            {CURRENCY_FORMAT.format(metric.value)}
                        </td>
                        <td className="text-right" style={{ width: `${isMobile ? '10%' : '15%'}` }}>
                            <Loading>
                                <Button type={'button'} inline color={'primary'} onClick={() => props.onDelete(metric.id)} label={''} icon="fas fa-remove"></Button>
                            </Loading>
                        </td>
                    </tr>
                );
            })
        );
    };

    return (
        <Card title={t('home.overview')} color={'primary'} icon={'fas fa-list'} loading={data.length === 0} minimizable maximizable>
            <Table thead={[]} tbody={listMetrics()} />
        </Card>
    );
};

export default Overview;
