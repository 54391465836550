import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import { useBloc } from '../../state/state';
import MetricsCubit from '../../state/blocs/MetricsCubit';
import DashboardsCubit from '../../state/blocs/DashboardsCubit';

import DashboardReport from '../../components/DashboardReport/DashboardReport';
import Overview from '../../components/Overview/Overview';
import ReportByGroups from '../../components/ReportByGroups/ReportByGroups';
import AddForm from '../../ui/AddForm';

import { CURRENCY_FORMAT } from '../../constants/constants';
import { calculateCurrentMonthProfit, calculateDifferenceFromLastMonth, generateMonthNames, sumPricePerMonthPerCurrency } from '../../utils/chartUtils';
import { splitMetricsForLastXMonths } from '../../utils/contractUtils';

const Home = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [metrics, { loadMetrics, deleteMetric }] = useBloc(MetricsCubit);
    const [dashboard] = useBloc(DashboardsCubit);

    const [, setRefreshState] = React.useState<number>(1);

    // Context
    const [user, setUser] = React.useContext(UserContext);

    const logout = () => {
        setUser(null);
        navigate('/');
    };

    useEffect(() => {
        loadMetrics(user, logout);
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setRefreshState((r) => r + 1);
    }, [dashboard]);

    const clickDelete = async (id: number) => {
        await deleteMetric(user, id, logout);
        loadMetrics(user, logout);
    };

    const renderWebDiv = () => {
        return (
            <>
                <div className="grid">
                    <div>
                        <AddForm />
                    </div>
                    <div>
                        <ReportByGroups data={slicedMetrics} />
                    </div>
                </div>
                <div className="grid">
                    <DashboardReport
                        title={t('home.realized-profit')}
                        icon={'fas fa-chart-line'}
                        reporting={t('home.profit')}
                        reportingValue={CURRENCY_FORMAT.format(+profitThisMonth)}
                        loading={metrics === null}
                        reportPercent={lastMonthDifference}
                        labels={labels}
                        data={chartData}
                        reportSince={t('home.since-last-month')}
                        noDataText={t('home.no-data')}
                    />
                </div>
                <div>
                    <div className="grid">
                        <div>
                            <Overview data={slicedMetrics} onDelete={clickDelete} />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------
    const months = dashboard.months - 1;
    const slicedMetrics = splitMetricsForLastXMonths(months, metrics);
    const labels = generateMonthNames(months);
    const chartData = sumPricePerMonthPerCurrency(labels, slicedMetrics);
    const lastMonthDifference = calculateDifferenceFromLastMonth(chartData);
    const profitThisMonth = calculateCurrentMonthProfit(chartData);
    return renderWebDiv();
};

export default Home;
