import { get, put, post, del } from './api';

const timeoutResponse = {
    error: true,
    msg: 'BACKEND_TOO_BUSY',
};

const getConfig = (token: string) => {
    return { headers: { Authorization: 'Bearer ' + token } };
};

const apiProvider = {
    getProfile: async (userToken: string, logout: () => void) => {
        try {
            const response = await get(`/v1/profile`, getConfig(userToken), logout, 'userProfileGetUser');
            return response.data;
        } catch (e) {
            return timeoutResponse;
        }
    },
    putProfile: async (userToken: string, firstName: string, lastName: string, dateOfBirth: string, logout: () => void) => {
        try {
            const response = await put(
                `/v1/profile`,
                {
                    first_name: firstName,
                    last_name: lastName,
                    date_of_birth: dateOfBirth,
                },
                getConfig(userToken),
                logout,
                'userProfileUpdateUser'
            );
            return response.data;
        } catch (e) {
            return timeoutResponse;
        }
    },
    deleteProfile: async (userToken: string, logout: () => void) => {
        try {
            const response = await del(`/v1/profile`, getConfig(userToken), logout, 'userProfileDeleteUser');
            return response.data;
        } catch (e) {
            return timeoutResponse;
        }
    },

    userFacebookLogin: async (facebookUserId: string, email: string, accessToken: string, expiresAt: number, name: string, url: string, logout: () => void) => {
        try {
            const response = await post(
                `/v1/facebook-login`,
                {
                    facebookUserId,
                    email,
                    access_token: accessToken,
                    expires_at: expiresAt,
                    name,
                    url,
                },
                {},
                logout,
                'userFacebookLogin'
            );

            return response.data;
        } catch (e) {
            return timeoutResponse;
        }
    },

    userGoogleLogin: async (googleUserId: string, email: string, accessToken: string, expiresAt: number, firstName: string, lastName: string, url: string, logout: () => void) => {
        try {
            const response = await post(
                `/v1/google-login`,
                {
                    googleUserId,
                    email,
                    access_token: accessToken,
                    expires_at: expiresAt,
                    first_name: firstName,
                    last_name: lastName,
                    url,
                },
                {},
                logout,
                'userGoogleLogin'
            );

            return response.data;
        } catch (e) {
            return timeoutResponse;
        }
    },

    userLogin: async (email: string, password: string, logout: () => void) => {
        try {
            const response = await post(
                `/v1/login`,
                {
                    email,
                    password,
                },
                {},
                logout,
                'userLogin'
            );

            return response.data;
        } catch (e) {
            return timeoutResponse;
        }
    },

    userRequestPasswordReset: async (email: string, logout: () => void) => {
        try {
            const response = await post(
                `/v1/request-password-reset`,
                {
                    email,
                },
                {},
                logout,
                'userRequestPasswordReset'
            );

            return response.data;
        } catch (e) {
            return timeoutResponse;
        }
    },

    userPasswordReset: async (token: string, password: string, logout: () => void) => {
        try {
            const response = await post(
                `/v1/reset-password`,
                {
                    token,
                    password,
                },
                {},
                logout,
                'userPasswordReset'
            );

            return response.data;
        } catch (e) {
            return timeoutResponse;
        }
    },

    signupRegisterAccount: async (email: string, password: string, logout: () => void) => {
        try {
            const response = await post(
                `/v1/register-user`,
                {
                    email: email,
                    password: password,
                },
                {},
                logout,
                'signupRegisterAccount'
            );

            return response.data;
        } catch (e) {
            return timeoutResponse;
        }
    },

    signupVerifyAccount: async (token: string, logout: () => void) => {
        try {
            const response = await get(`/v1/verify-account/${token}`, {}, logout, 'signupVerifyAccount');
            return response.data;
        } catch (e) {
            return timeoutResponse;
        }
    },

    getMetrics: async (userToken: string, logout: () => void) => {
        try {
            const response = await get(`/v1/metrics`, getConfig(userToken), logout, 'getMetrics');
            return response.data;
        } catch (e) {
            return timeoutResponse;
        }
    },
    postMetrics: async (userToken: string, type: string, date: string, value: number, logout: () => void) => {
        try {
            const response = await post(
                `/v1/metrics`,
                {
                    type: type,
                    date: date,
                    value: value,
                },
                getConfig(userToken),
                logout,
                'postMetrics'
            );
            return response.data;
        } catch (e) {
            return timeoutResponse;
        }
    },
    deleteMetrics: async (userToken: string, id: number, logout: () => void) => {
        try {
            const response = await del(`/v1/metrics/${id}`, getConfig(userToken), logout, 'deleteMetrics');
            return response.data;
        } catch (e) {
            return timeoutResponse;
        }
    },
};

export default apiProvider;
