import moment from 'moment';
import { IMetric } from '../interfaces/interfaces';
import { DATE_FORMAT } from '../constants/constants';

export function splitMetricsForLastXMonths(numberOfMonths: number, metrics: Array<IMetric>): Array<IMetric> {
    const now = moment().endOf('month').format(DATE_FORMAT);
    const past = moment().subtract(numberOfMonths, 'months').startOf('month').format(DATE_FORMAT);

    const slicedMetrics = metrics.filter((metric: IMetric) => {
        return metric.date >= past && metric.date <= now;
    });

    return slicedMetrics;
}
