import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import logo from '../../assets/img/logo.svg';

import './UserProfile.css';

import { useBloc } from '../../state/state';
import ProfilesCubit from '../../state/blocs/ProfilesCubit';

import { UserContext } from '../../context/UserContext';
import { NAME_REGEX } from '../../utils/utils';

import Card from '../../components/elements/Card/Card';
import Input from '../../components/elements/Input/Input';
import Loading from '../../ui/Loading/Loading';
import Button from '../../components/elements/Button/Button';

const UserProfile = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [profile, { loadProfile, updateProfile, deleteProfile }] = useBloc(ProfilesCubit);

    const [dateOfBirth, setDateOfBirth] = React.useState<string>('');
    const [firstName, setFirstName] = React.useState<string>('');
    const [lastName, setLastName] = React.useState<string>('');

    // Context
    const [user, setUser] = React.useContext(UserContext);

    // Logout function
    const logout = () => {
        setUser(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        loadProfile(user, logout);
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------
    const clickDeleteMyProfile = async () => {
        await deleteProfile(user, logout);
        setUser(null);
        localStorage.clear();
        navigate('/login');
    };

    const clickUpdateMyProfile = async () => {
        await updateProfile(user, firstName, lastName, dateOfBirth, logout);
        loadProfile(user, logout);
    };

    const validateBirthday = (date: string) => {
        const userBirthday = new Date(date);
        const currentDate = new Date();

        if (userBirthday.getFullYear() < 1950) {
            return false;
        }

        if (userBirthday.getFullYear() > 2020) {
            return false;
        }

        return currentDate.getFullYear() - userBirthday.getFullYear() >= 13;
    };

    const clickBack = () => {
        navigate('/');
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------
    return (
        <>
            <div className="grid">
                <div>
                    <Card title={t('user-profile.profile')} color="primary" icon="fas fa-user">
                        {profile && (
                            <>
                                <div style={{ textAlign: 'center' }}>
                                    <img
                                        style={{ maxHeight: '96px', maxWidth: '96px', height: '100%', width: '100%' }}
                                        alt="User"
                                        src={profile?.facebookImage || profile?.googleImage || logo}
                                    />
                                </div>

                                <h3 style={{ textAlign: 'center' }}>
                                    {profile.firstName} {profile.lastName}
                                </h3>

                                <ul>
                                    {profile.dateOfBirth && (
                                        <li>
                                            <i className="fas fa-calendar" />
                                            &nbsp;
                                            <strong style={{ textAlign: 'right' }}>{t('user-profile.dob')}</strong> <span style={{ float: 'right' }}>{profile.dateOfBirth}</span>
                                        </li>
                                    )}
                                    {profile.webEmail && (
                                        <li>
                                            <i className="fas fa-envelope" />
                                            &nbsp;
                                            <strong>{t('user-profile.email-web')}</strong>
                                            <span style={{ float: 'right' }}>{profile.webEmail}</span>
                                        </li>
                                    )}
                                    {profile.facebookEmail && (
                                        <li>
                                            <i className="fab fa-facebook" />
                                            &nbsp;
                                            <strong>{t('user-profile.email-facebook')}</strong>
                                            <span style={{ float: 'right' }}>{profile.facebookEmail}</span>
                                        </li>
                                    )}
                                    {profile.googleEmail && (
                                        <li>
                                            <i className="fab fa-google" />
                                            &nbsp;
                                            <strong>{t('user-profile.email-google')}</strong> <span style={{ float: 'right' }}>{profile.googleEmail}</span>
                                        </li>
                                    )}
                                    {profile.consentGivenAt && (
                                        <li>
                                            <i className="fas fa-clock" />
                                            &nbsp;
                                            <strong>{t('user-profile.consent-given')}</strong>
                                            <span style={{ float: 'right' }}>{profile.consentGivenAt}</span>
                                        </li>
                                    )}
                                    <li>
                                        <i className="fas fa-signature" />
                                        &nbsp;
                                        <strong>{t('user-profile.consented')}</strong>
                                        <span style={{ float: 'right' }}>
                                            <a href="/privacy-policy" target="_blank">
                                                {t('user-profile.privacy-policy')}
                                            </a>
                                        </span>
                                    </li>
                                </ul>
                            </>
                        )}
                    </Card>
                </div>
                <div>
                    <Card title={t('user-profile.update')} icon="fas fa-user-edit">
                        {profile && (
                            <>
                                <Input
                                    id={'dateOfBirth'}
                                    type={'date'}
                                    name={'Date of birth'}
                                    value={dateOfBirth}
                                    placeholder={new Date(profile.dateOfBirth).toLocaleString()}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDateOfBirth(e.target.value)}
                                    isInvalid={!validateBirthday(dateOfBirth)}
                                    isInvalidHelp={t('input.valid-dob')}
                                    required={true}
                                    autocomplete={'bday'}
                                />
                                <Input
                                    id={'firstName'}
                                    type={'text'}
                                    name={'First name'}
                                    value={firstName}
                                    placeholder={profile.firstName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                                    isInvalid={!NAME_REGEX.test(firstName)}
                                    isInvalidHelp={t('input.valid-first-name')}
                                    required={true}
                                    autocomplete={'given-name'}
                                />
                                <Input
                                    id={'lastName'}
                                    type={'text'}
                                    name={'Last name'}
                                    value={lastName}
                                    placeholder={profile.lastName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                                    isInvalid={!NAME_REGEX.test(lastName)}
                                    isInvalidHelp={t('input.valid-last-name')}
                                    required={true}
                                    autocomplete={'family-name'}
                                />
                            </>
                        )}
                    </Card>
                </div>
            </div>
            <br />
            <Loading>
                <div className="grid">
                    <div>
                        <Button block type={'button'} color={'primary'} onClick={clickBack} label={t('button.back')} />
                    </div>
                    <div>
                        <Button
                            block
                            type={'button'}
                            color={'primary'}
                            onClick={clickUpdateMyProfile}
                            label={t('button.update-account')}
                            disabled={!NAME_REGEX.test(firstName) || !NAME_REGEX.test(lastName) || !validateBirthday(dateOfBirth)}
                        />
                    </div>
                    <div>
                        <Button block type={'button'} color={'primary'} onClick={clickDeleteMyProfile} label={t('button.delete-account')} />
                    </div>
                </div>
            </Loading>
        </>
    );
};

export default UserProfile;
