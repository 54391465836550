import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';

import logo from '../../assets/img/logo.svg';

import apiProvider from '../../api';
import { UserContext } from '../../context/UserContext';
import { EMAIL_REGEX } from '../../utils/utils';

import Input from '../../components/elements/Input/Input';
import Loading from '../../ui/Loading/Loading';
import Card from '../../components/elements/Card/Card';

const Wrapper = styled.div`
    margin: 0 auto;
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
`;

const MainContent = styled.main`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    position: relative;
    flex: 1;
    height: 100vh;
`;

const ForgotMyPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [email, setEmail] = React.useState<string>('');
    const [token, setToken] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [repeatPassword, setRepeatPassword] = React.useState<string>('');
    const [showPasswordFields, setShowPasswordFields] = React.useState<boolean>(false);

    // Context
    const [, setUser] = React.useContext(UserContext);

    // Logout function
    const logout = () => {
        setUser(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        setUser(null);
        const query = new URLSearchParams(location.search);
        const queryToken = query.get('token');
        if (queryToken != null) {
            setShowPasswordFields(true);
            setToken(queryToken);
        }

        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickResetPassword = async () => {
        const response = await apiProvider.userRequestPasswordReset(email, logout);

        if (!response || response.error) return;

        toast.success(response);
    };

    const clickLogin = async () => {
        navigate('/login');
    };

    const clickRegister = async () => {
        navigate('/signup');
    };

    const clickSetNewPassword = async () => {
        const response = await apiProvider.userPasswordReset(token, password, logout);

        if (!response || response.error) return;

        toast.success(response);
        navigate('/login');
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <Wrapper className="wrapper">
            <MainContent>
                <div style={{ minWidth: '360px', width: '100%', maxWidth: '360px' }}>
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <img src={logo} alt="Logo" style={{ maxHeight: '128px' }} />
                    </div>
                    <Loading>
                        <Card noTitle>
                            {!showPasswordFields && (
                                <form>
                                    <p style={{ textAlign: 'center' }}>{t('forgot-my-password.title')}</p>
                                    <Input
                                        id={'email'}
                                        type={'email'}
                                        name={'email'}
                                        value={email}
                                        placeholder={'user@example.com'}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                                        isInvalid={!EMAIL_REGEX.test(email)}
                                        isInvalidHelp={t('input.valid-email')}
                                        required={true}
                                        autocomplete={'email'}
                                    />
                                    <button type="button" onClick={clickResetPassword} disabled={!EMAIL_REGEX.test(email)}>
                                        {t('forgot-my-password.reset-password')}
                                    </button>
                                    <label>
                                        <span style={{ color: '#df135e' }} onClick={clickLogin}>
                                            {t('common.already-registered')}
                                        </span>
                                    </label>
                                    <label>
                                        <span style={{ color: '#df135e' }} onClick={clickRegister}>
                                            {t('common.register')}
                                        </span>
                                    </label>
                                </form>
                            )}
                            {showPasswordFields && (
                                <form>
                                    <p className="text-center">{t('forgot-my-password.enter-new-password')}</p>
                                    <Input
                                        id={'password'}
                                        type={'password'}
                                        name={'password'}
                                        value={password}
                                        placeholder={'••••••••'}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                                        isInvalid={password.length < 6}
                                        isInvalidHelp={t('input.valid-password')}
                                        required={true}
                                        autocomplete={'new-password'}
                                    />
                                    <Input
                                        id={'password'}
                                        type={'password'}
                                        name={'password'}
                                        value={repeatPassword}
                                        placeholder={'••••••••'}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRepeatPassword(e.target.value)}
                                        isInvalid={password.length < 6}
                                        isInvalidHelp={t('input.valid-repeat-password')}
                                        required={true}
                                        autocomplete={'new-password'}
                                    />
                                    <button type="button" onClick={clickSetNewPassword} disabled={password.length < 6 || repeatPassword !== password}>
                                        {t('button.set-new-password')}
                                    </button>
                                </form>
                            )}
                        </Card>
                    </Loading>
                </div>
            </MainContent>
        </Wrapper>
    );
};

export default ForgotMyPassword;
