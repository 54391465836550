import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

import i18n from '../translations/i18n';

const api = axios.create({
    timeout: 10000,
    headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
    },
    baseURL: process.env.REACT_APP_API,
    validateStatus: function (status: number) {
        return true;
    },
});

const que: Set<string> = new Set<string>();

export const queMethods = {
    add: (key: string): any => que.add(key),
    remove: (key: string): any => que.delete(key),
    has: (key: string): boolean => que.has(key),
};

export function useQue() {
    const [current, setQue] = useState<Set<string>>(que);

    useEffect(() => {
        queMethods.add = (key: string) => {
            que.add(key);
            setQue(new Set(que));
        };
        queMethods.remove = (key: string) => {
            que.delete(key);
            setQue(new Set(que));
        };
    });

    return current;
}

// api.defaults.headers = {
//     const getConfig = (token: string) => {
//         return { headers: { Authorization: 'Bearer ' + token } };
//     };
// }

export const get = async (url: string, config: any, logout: () => void, key: string) => {
    if (queMethods.has(key)) {
        console.warn(`request ${key} already pending`);
        return { error: '' };
    }

    try {
        queMethods.add(key);
        const response = await api.get(url, config);
        queMethods.remove(key);

        if (response.data.ok) {
            return response.data;
        } else if (response.data.logout) {
            toast.warn(i18n.t(response.data.msg) as string);
            localStorage.clear();
            logout();
            return { data: { error: true } };
        } else if (response.data.info) {
            toast.info(i18n.t(response.data.msg) as string);
            return { data: { error: true } };
        } else if (response.data.warn) {
            toast.warn(i18n.t(response.data.msg) as string);
            return { data: { error: true } };
        } else if (response.data.error) {
            toast.error(i18n.t(response.data.msg) as string);
            return { data: { error: true } };
        } else {
            toast.error(response.data);
            return { data: { error: true } };
        }
    } catch (e: any) {
        queMethods.remove(key);
        console.error(e);
        toast.error(e);
        throw e;
    }
};

export const post = async (url: string, payload: any, config: any, logout: () => void, key: string) => {
    if (queMethods.has(key)) {
        console.warn(`request ${key} already pending`);
        return { error: '' };
    }

    try {
        queMethods.add(key);
        const response = await api.post(url, payload, config);
        queMethods.remove(key);

        if (response.data.ok) {
            return response.data;
        } else if (response.data.logout) {
            localStorage.clear();
            toast.warn(i18n.t(response.data.msg) as string);
            logout();
            return { data: { error: true } };
        } else if (response.data.info) {
            toast.info(i18n.t(response.data.msg) as string);
            return { data: { error: true } };
        } else if (response.data.warn) {
            toast.warn(i18n.t(response.data.msg) as string);
            return { data: { error: true } };
        } else if (response.data.error) {
            toast.error(i18n.t(response.data.msg) as string);
            return { data: { error: true } };
        } else {
            toast.error(response.data);
            return { data: { error: true } };
        }
    } catch (e: any) {
        queMethods.remove(key);
        console.error(e);
        toast.error(e);
        throw e;
    }
};

export const put = async (url: string, payload: any, config: any, logout: () => void, key: string) => {
    if (queMethods.has(key)) {
        console.warn(`request ${key} already pending`);
        return { error: '' };
    }
    try {
        queMethods.add(key);
        const response = await api.put(url, payload, config);
        queMethods.remove(key);

        if (response.data.ok) {
            return response.data;
        } else if (response.data.logout) {
            toast.warn(i18n.t(response.data.msg) as string);
            localStorage.clear();
            logout();
            return { data: { error: true } };
        } else if (response.data.info) {
            toast.info(i18n.t(response.data.msg) as string);
            return { data: { error: true } };
        } else if (response.data.warn) {
            toast.warn(i18n.t(response.data.msg) as string);
            return { data: { error: true } };
        } else if (response.data.error) {
            toast.error(i18n.t(response.data.msg) as string);
            return { data: { error: true } };
        } else {
            toast.error(response.data);
            return { data: { error: true } };
        }
    } catch (e: any) {
        queMethods.remove(key);
        console.error(e);
        toast.error(e);
        throw e;
    }
};

export const del = async (url: string, config: any, logout: () => void, key: string) => {
    if (queMethods.has(key)) {
        console.warn(`request ${key} already pending`);
        return { error: '' };
    }
    try {
        queMethods.add(key);
        const response = await api.delete(url, config);
        queMethods.remove(key);

        if (response.data.ok) {
            return response.data;
        } else if (response.data.logout) {
            toast.warn(i18n.t(response.data.msg) as string);
            localStorage.clear();
            logout();
            return { data: { error: true } };
        } else if (response.data.info) {
            toast.info(i18n.t(response.data.msg) as string);
            return { data: { error: true } };
        } else if (response.data.warn) {
            toast.warn(i18n.t(response.data.msg) as string);
            return { data: { error: true } };
        } else if (response.data.error) {
            toast.error(i18n.t(response.data.msg) as string);
            return { data: { error: true } };
        } else {
            toast.error(response.data);
            return { data: { error: true } };
        }
    } catch (e: any) {
        queMethods.remove(key);
        console.error(e);
        toast.error(e);
        throw e;
    }
};

export default api;
