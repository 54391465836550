import { ComponentProps } from 'react';

interface IProps extends ComponentProps<any> {
    type?: 'button' | 'submit' | 'reset';
    color?: 'primary' | 'success' | 'danger' | 'warning' | 'secondary';
    label?: string | any;
    onClick?: any;
    disabled?: boolean;
    block?: boolean;
    xs?: boolean;
    sm?: boolean;
    lg?: boolean;
    flat?: boolean;
    outline?: boolean;
    gradient?: boolean;
    icon?: string;
}

const Button = (props: IProps) => {
    const { type, color, block, label, onClick, disabled, outline, gradient, icon } = props;

    const colorClass = gradient ? `bg-gradient-${color} ` : outline ? `outline ` : `btn-${color} `;
    const buttonClass = colorClass;

    return (
        <button style={{ width: block ? '100%' : '' }} onClick={onClick} type={type} className={buttonClass} disabled={disabled || false}>
            {icon && (
                <>
                    <i className={icon} />
                    &nbsp;
                </>
            )}
            {label}
        </button>
    );
};

Button.defaultProps = {
    type: 'button',
    color: 'primary',
    label: 'Button',
    onClick: undefined,
    disabled: false,
    outline: false,
    gradient: false,
    icon: undefined,
    block: undefined,
};

export default Button;
