import React, { ComponentProps } from 'react';
import { ComposedChart, XAxis, YAxis, Tooltip, Bar, Line, ResponsiveContainer } from 'recharts';

interface IProps extends ComponentProps<any> {
    title?: string;
    icon?: string;
}

interface ChartData {
    month: string;
    income: number;
    expense: number;
    profit: number;
}

interface DataItem {
    label: string;
    data: number[];
}

const Chart = (props: IProps) => {
    const generateChartData = (labels: string[], data: DataItem[]): ChartData[] => {
        let convertedData: ChartData[] = [];

        if (data && data.length >= 2) {
            convertedData = data[0].data.map((_, index) => {
                const label = labels[index];
                const income = data[0].data[index] || 0;
                const expense = data[1].data[index] || 0;
                const profit = income - expense;
                return { month: label, income, expense, profit };
            });
        }

        return convertedData;
    };

    const { labels, data } = props;
    const chartData: ChartData[] = generateChartData(labels, data);

    return (
        <ResponsiveContainer width={'100%'} height={400}>
            <ComposedChart data={chartData} margin={{ left: 0, right: 0 }}>
                <Tooltip />
                <XAxis dataKey="month" />
                <YAxis domain={[0, 'dataMax']} hide={true} />
                <Bar dataKey="income" fill="#bbc6ce" barSize={40} />
                <Bar dataKey="expense" fill="#888" barSize={40} />
                <Line type="monotone" label={{ fill: '#fff', fontSize: '1.5rem', position: 'top', offset: 16 }} dataKey="profit" stroke="#fe2273" strokeWidth={6} dot={{ r: 5 }} />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default Chart;
